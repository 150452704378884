import styles from "./ButtonCreate.module.scss";
import { Link } from "react-router-dom";
import * as Routes from "../routes";

const ButtonCreate = () => {
  return (
    <>
      <button className={styles.btncreate} type="submit">
        <Link className={styles.btncreateLink} to={Routes.HOME}>
          Create account
        </Link>
      </button>
    </>
  );
};

export default ButtonCreate;
