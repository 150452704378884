import React from "react";
// import { useField, ErrorMessage } from "formik";
import { useQuery } from "@apollo/client";
import { GET_USERS } from "../graphql/users";

export const UserList = ({ label, ...props }) => {
	const { loading, error, data } = useQuery(GET_USERS);

  if (loading) {
    return (
      <p>Loading...</p>
    )
  }

  if (error) {
    return 'Could not load the users!'
  }

  return (
    <div>
      {
        data.users.map((e) => {
          return (
            <h1>{e.first_name}</h1>
          )
        })
      }
    </div>
  );
};

export default UserList;
