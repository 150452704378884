import styles from './ButtonContact.module.scss'

const ButtonContact = () => {
    return (
        <>
            <button className={styles.btncontact} type="submit">Submit</button>
        </>
    );
};

export default ButtonContact;