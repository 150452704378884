import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as Routes from "./routes";
import styles from "./App.module.scss";
import {
  HomePage,
  SignInPage,
  BoardPage,
  RegisterPage,
  ContactPage,
  ProfilePage,
  AboutPage,
  AccountPage,
  CollabPage,
  ListPage,
  PrivacyPage,
  TimeLineViewPage,
} from "./pages";

function App() {
  return (
    <div className={styles.app}>
      <Router basename={""}>
        <Switch>
          <Route exact path={Routes.LANDING} component={SignInPage} />
          <Route path={Routes.HOME} component={HomePage} />
          <Route path={Routes.REGISTER} component={RegisterPage} />
          <Route path={Routes.BOARD} component={BoardPage} />
          <Route path={Routes.COLLAB} component={CollabPage} />
          <Route path={Routes.LIST} component={ListPage} />
          <Route path={Routes.ABOUT} component={AboutPage} />
          <Route path={Routes.CONTACT} component={ContactPage} />
          <Route path={Routes.PROFILE} component={ProfilePage} />
          <Route path={Routes.ACCOUNT} component={AccountPage} />
          <Route path={Routes.PRIVACY} component={PrivacyPage} />
          <Route path={Routes.TIMELINE} component={TimeLineViewPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
