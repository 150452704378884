import styles from "./Sidebar.module.scss";
import logo from "../assets/white_logo.svg";
import { Link } from "react-router-dom";
import * as Routes from "../routes";

const Sidebar = () => {
  return (
    <>
      <div className={styles.sideBar}>
        <Link to={Routes.HOME}>
         <img className={styles.logo} src={logo} alt="" />
        </Link>
        <ul className={styles.side}>
          <Link to={Routes.HOME}>Dashboard</Link>
          <li>Project 1</li>
          <li>Project 2</li>
        </ul>

        <ul className={styles.info}>
          <li>
            <Link to={Routes.ABOUT}>About</Link>
          </li>
          <li>
            <Link className={styles.infoSmall} to={Routes.CONTACT}>
              Contact
            </Link>
          </li>
          <li>
            <Link to={Routes.PROFILE}>Profile</Link>
          </li>
          <li>
            <Link to={Routes.PRIVACY}>Privacy</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
