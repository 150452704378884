import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./MainNavigation.module.scss";
import logo from "../assets/profiel.svg";
import * as Routes from "../routes";
import menu from "../assets/hamburger.svg";

export default function MainNavigation() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);
  return (
    <>
      <div className={styles.header}>
        <nav>
          {(toggleMenu || screenWidth > 767) && (
            <ul className={styles.navigation}>
              <li className={styles.links}>
                <Link to={Routes.BOARD}>Board</Link>
              </li>

              <li className={styles.links}>
                <Link to={Routes.LIST}>List</Link>
              </li>

              <li className={styles.links}>
                <Link to={Routes.TIMELINE}>Timeline</Link>
              </li>

              <li className={styles.links}>
                <Link to={Routes.COLLAB}>Collaborate</Link>
              </li>

              <div className={styles.searchBalk}>
                <form className={styles.nav_form}>
                  <label className={styles.label} htmlFor="query"></label>
                  <input
                    className={styles.search_nav}
                    type="text"
                    name="query"
                    placeholder="Search"
                  />
                </form>
                <Link to={Routes.PROFILE}>
                  <img className={styles.avatar} src={logo} alt="logo" />
                </Link>
              </div>
            </ul>
          )}
          <div onClick={toggleNav} className={styles.btn}>
            <img className={styles.menu} src={menu} alt={logo} />
          </div>
        </nav>
      </div>
    </>
  );
}
