import { BaseLayout } from "../layout";
import styles from "./ContactPage.module.scss";
import logo from "../assets/logo.svg";
import ButtonContact from "../components/ButtonContact";
import { Formik, Form } from "formik";
import { TextField } from "../components";
import * as Yup from "yup";
import MobileButtons from "../components/MobileButtons";

const ContactPage = () => {
  const validate = Yup.object({
    name: Yup.string().required("Required"),
    phonenumber: Yup.string().required("Required"),
    email: Yup.string().email(5, "Email is invalid").required("Required"),
    country: Yup.string().required("Required"),
  });

  return (
    <BaseLayout>
      <div className={styles.contact}>
        <h1>Contact Us</h1>
        <div className={styles.logo__black}>
          <img className={styles.logo} src={logo} alt="2gether" />

          <p>Whether you’re Interested in implementing 2gether with your team, or
            have any questions about the product features or troubleshooting,
            we’re here to help.
          </p>
          <p>
            Use this form for all your inquiries. 2gether will respond to your
            questions and comments using the email address provided (check your
            junk mail!) within 1 business day. Be sure to visit the FAQ section
            for commonly asked questions to see if your inquiries are addressed
            there.
          </p>
          <p>Please fill out the form below and submit.</p>
        </div>

        <Formik
          initialValues={{
            name: "",
            phonenumber: "",
            email: "",
            country: "",
            feedback: "",
          }}
          validationSchema={validate}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          <div className={styles.formik}>
            <Form>
              <div className={styles.input_twice}>
                <TextField
                  className={styles.name}
                  label="Name"
                  name="email"
                  type="email"
                />
                <TextField
                  className={styles.name}
                  label="Phonenumber"
                  name="phonenumber"
                  type="text"
                />
              </div>
              <div className={styles.input_twice}>
                <TextField
                  className={styles.name}
                  label="Email"
                  name="email"
                  type="text"
                />
                <TextField
                  className={styles.name}
                  label="Country"
                  name="country"
                  type="text"
                />
              </div>
              <TextField
                className={styles.name}
                label="Feedback/Questions"
                name="feedback"
                type="text"
              />
            </Form>
          </div>
        </Formik>
        <ButtonContact />
      </div>
      <MobileButtons />
    </BaseLayout>
  );
};

export default ContactPage;
