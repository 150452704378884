import React from "react";
import styles from "./FAQ.module.scss";

function FAQ({ faq, index, toggleFAQ }) {
  return (
    <div
      className={styles.faq + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className={styles.faqquestion}>{faq.question}</div>
      <div className={styles.faqanswer}>{faq.answer}</div>
    </div>
  );
}

export default FAQ;
