import styles from "./BoardCards.module.scss";
import { useQuery } from "@apollo/client";
import { GET_TASKS } from "../graphql/tasks";
import logo from "../assets/profiel.svg";

const BoardCards = ({ label, ...props }) => {
  const { loading, error, data } = useQuery(GET_TASKS);

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return "Could not load the tasks!";
  }

  return (
    <>
      <div className={styles.boardCard}>
        <h1 className={styles.titleCard}>Team 1</h1>
        <div className={styles.allcards}>
          <ul className={styles.container__items}>
            {data.tasks.map((e) => {
              console.log(data.tasks);
              return (
                <li className={styles.container__Cards}>
                  <p>{e.name}</p>
                  <p>{e.priority.name}</p>
                  <p>
                    <img className={styles.logo} src={logo} alt="2gether" />
                    {e.user.first_name}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default BoardCards;
