import styles from "./RegisterLayout.module.scss";
import logo from "../assets/logo.svg";
import { ButtonCreate } from "../components";
import { Formik, Form } from "formik";
import { TextField } from "../components";
import * as Yup from "yup";
import * as Routes from "../routes";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_USER } from "../graphql/users";

const RegisterLayout = () => {
  const validate = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required('Required'),
    // phonenumber: Yup.string()
    //   .max(10, "Phonenumber is invalid")
    //   .required("Required"),
    email: Yup.string().email("Email is invalid").required("Required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Required"),
  });

  // Create userMutation makes a new user in the database
  const [createUser, { data, loading, error }] = useMutation(CREATE_USER);

  if (error) {
    return "Could not create user!";
  }

  return (
    <>
      <div className={styles.registercontainer}>
        <div className={styles.logo__black}>
          <img className={styles.logo} src={logo} alt="2gether" />
          <h1>2gether</h1>
        </div>

        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
          }}
          onSubmit={(values) => {
            console.log(values);
            if (values.password === values.confirmPassword) {
              createUser({variables: { firstName: values.firstName, lastName: values.lastName, email: values.email, pwd: values.password}});
            } else {
              return 'Passwords did not match!';
            }
          }}
          validationSchema={validate}
        >
          
            {({handleSubmit}) => (
              <Form onSubmit={handleSubmit}>
              <TextField
                className={styles.name}
                label="First Name"
                name="firstName"
                type="text"
                placeholder='John'
              />
              <TextField
                className={styles.name}
                label="Last Name"
                name="lastName"
                type="text"
                placeholder='Doe'
              />
              <TextField
                className={styles.name}
                label="Email"
                name="email"
                type="email"
                placeholder='johndoe@gmail.com'
              />
              <TextField
                className={styles.name}
                label="Password"
                name="password"
                type="password"
                placeholder='asfsaadfasf'
              />
              <TextField
                className={styles.name}
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                placeholder='asfsaadfasf'
              />
              {/* <button type="submit">Create Account</button> */}
              <ButtonCreate />
            </Form>
            )}
          
        </Formik>
        <div className={styles.forlink}>
          <Link to={Routes.LANDING}>Already an account? Sign in</Link>
        </div>

        <p className={styles.privacy}>
          This website is designed by Seneca Coil Team / ahs Privacy Policy and
          Terms apply.
        </p>
      </div>
    </>
  );
};

export default RegisterLayout;
