// export const LANDING = "/";
export const HOME = "/home";
export const CONTACT = "/contact";
export const PROFILE = "/profile";
export const ABOUT = "/about";
export const FOUROFOUR = "/404";
export const PRIVACY = "/privacy";

// views
export const BOARD = "/board";
export const LIST = "/list";
export const TIMELINE = "/timeline";
export const COLLAB = "/collab";

// user pages
export const LANDING = "/";
export const SIGN_OUT = "/signout";
export const REGISTER = "/register";
export const ACCOUNT = "/account";
