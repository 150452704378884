import { UserList } from "../components";
import BoardCards from "../components/BoardCards";
import { BaseLayout } from "../layout";

const BoardPage = () => {
  return (
    <BaseLayout>
      <BoardCards />
      <UserList />
    </BaseLayout>
  );
};

export default BoardPage;
