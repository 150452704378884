import { Link } from "react-router-dom";
import * as Routes from "../routes";
import styles from "./ButtonLogin.module.scss";

const ButtonLogin = () => {
  return (
    <>
      <button className={styles.btnlogin} type="submit">
        <Link className={styles.btnLoginLink} to={Routes.HOME}>
          Login
        </Link>
      </button>
    </>
  );
};

export default ButtonLogin;
