import { BaseLayout } from "../layout";
import styles from "./ListPage.module.scss";
import { useQuery } from "@apollo/client";
import { GET_TASKS } from "../graphql/tasks";
import MobileButtons from "../components/MobileButtons";

const ListPage = ({ label, ...props }) => {
  const { loading, error, data } = useQuery(GET_TASKS);

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return "Could not load the tasks!";
  }

  return (
    <BaseLayout>
      <div className={styles.list}>

        <button className={styles.taskbtn}>+ Daily Task</button>

        <div className={styles.tasks}>
          <h3>Team 1</h3>
          <ul className={styles.tasksList}>
            <li>
              <h5>Tasks</h5>
            </li>
            <li>
              <h5>Responsable</h5>
            </li>
            <li>
              <h5>Due date</h5>
            </li>
            <li>
              <h5>Priority</h5>
            </li>
          </ul>

          <div className={styles.tasks__items}>
            <ul className={styles.tasksList__item}>
              {data.tasks.map((e) => {
                return <li>{e.name}</li>;
              })}
            </ul>

            <ul className={styles.tasksList__item}>
              {data.tasks.map((e) => {
                return <li>{e.user.first_name}</li>;
              })}
            </ul>

            <ul className={styles.tasksList__item}>
              <li>01/06/2021</li>
              <li>01/06/2021</li>
              <li>01/06/2021</li>
              <li>03/06/2021</li>
              <li>02/06/2021</li>
              <li>05/06/2021</li>
            </ul>

            <ul className={styles.tasksList__item}>
              {data.tasks.map((e) => {
                return <li>{e.priority.name}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
      <MobileButtons />
    </BaseLayout>
  );
};

export default ListPage;
