import { gql } from "@apollo/client";

export const GET_TASKS = gql`
  query {
    tasks {
      id
      name
      description
      priority {
        name
      }
      completed
      user {
        id
        last_name
        first_name
      }
    }
  }
`;

/**
 * How to use:
 * const [addUser, { loading, error, data }] = useMutation(<<QUERY GOES HERE>>);
 * 
 * ----in form----
 * <form
		onSubmit={e => {
			e.preventDefault();
			addTodo({ variables: { text: input.value } });          
			input.value = '';
		}}
	>
		<input
			ref={node => {
				input = node;
			}}
		/>
		<button type="submit">Add Todo</button>
	</form>	
 * 
 */

export const CREATE_TASK = gql`
  mutation createTaskInput($input: CreateTaskInput!) {
    createTask(createTaskInput: $input) {
      id
      name
    }
  }
`;
