import { SigninLayout } from "../layout";

const SignInPage = () => {
    return (
      <SigninLayout>
          
      </SigninLayout>
    );
};
  
export default SignInPage;