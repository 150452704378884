import { BaseLayout } from "../layout";
import styles from "./ProfilePage.module.scss";
import logo from "../assets/profiel.svg";
import { Formik, Form } from "formik";
import { TextField } from "../components";
import * as Yup from "yup";
import MobileButtons from "../components/MobileButtons";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../graphql/users";

const ProfilePage = () => {
  const validate = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    // phonenumber: Yup.string().required("Required"),
    // email: Yup.string().email(5, "Email is invalid").required("Required"),
    // country: Yup.string().required("Required"),
    // ---- Currently disabled phonenumber, email, country validation ----
  });

  const [updateUser, { data, loading, error }] = useMutation(UPDATE_USER);

  if (error) {
    return "Could not load the User information!";
  }

  return (
    <BaseLayout>
      <div className={styles.profile}>
        <div className={styles.container__logo}>
          <img className={styles.logo} src={logo} alt="2gether" />
        </div>
        <h1>Profile</h1>
        <div className={styles.logo__black}>
          <p>
            Keep your profile information up-to-date. Edit the fields below and
            click Submit to save your changes.{" "}
          </p>
          <p>
            To deactivate your account, check the box beside Deactivate my
            account and click submit.{" "}
          </p>
          <p>Note: allow for 24 hours for your account to be inactive.</p>
        </div>

        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            phonenumber: "",
            email: "",
            country: "",
          }}
          validationSchema={validate}
          onSubmit={(values) => {
            console.log(values);
            updateUser({
              variables: {
                id: 3, // currently a hard-coded id, because sessions aren't working yet
                firstName: values.firstName,
                lastName: values.lastName,
              },
            });
          }}
        >
          <div>
            <Form>
              <div className={styles.input_twice}>
                <TextField
                  className={styles.name}
                  label="First name"
                  name="firstName"
                  type="text"
                />
                <TextField
                  className={styles.name}
                  label="Last name"
                  name="lastName"
                  type="text"
                />
                <TextField
                  className={styles.name}
                  label="Phonenumber"
                  name="phonenumber"
                  type="text"
                />
              </div>
              <div className={styles.input_twice}>
                <TextField
                  className={styles.name}
                  label="Email"
                  name="email"
                  type="text"
                />
                <TextField
                  className={styles.name}
                  label="Country"
                  name="country"
                  type="text"
                />
              </div>
              <button className={styles.btnsubmit} type="submit">
                Update
              </button>
            </Form>
          </div>
        </Formik>
      </div>
      <MobileButtons />
    </BaseLayout>
  );
};

export default ProfilePage;
