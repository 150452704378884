// import { Sidebar } from "../components";
import { BaseLayout } from "../layout";
import styles from "./AboutPage.module.scss";
import img from "../assets/personal.png";
import MobileButtons from "../components/MobileButtons";

const AboutPage = () => {
  return (
    <BaseLayout>
      <div className={styles.about}>
        <h1>About us ?</h1>
        <p className={styles.text}>
          The 2gether app is built and maintained by Quaranteam. We are students
          based in Toronto, Canada and Ghent, Belgium.
        </p>
        <p className={styles.text}>
          The app was built for students and professionals like us that seek to
          make the collaboration process efficient and fun. The life of
          individuals in academia can consist of juggling multiple priorities,
          so the purpose of the app is to provide some organization for our
          users to meet deadlines, delegate tasks and achieve their goals.
        </p>
        <p className={styles.text}>
          Originally called “Team 4”, the group rebranded Quaranteam to
          represent their shared experiences collaborating remotely during 2021.
          The Quaranteam is composed of two major departments: the Artevelde
          team handled the development and programming, while the Seneca team
          handled the technical writing and documentation.
        </p>
        <p className={styles.text}>
          The Quaranteam is passionate about collaboration and using technology
          to improve people’s lives. As we like to say, everything works better
          together.
        </p>

        <div className={styles.workers}>
          <h2>Who worked on this project ?</h2>
          <ul>
            <li>
              <img src={img} alt="silke"></img>
              <p>Silke</p>
              <p>Programmer</p>
            </li>
            <li>
              <img src={img} alt="Ryan"></img>
              <p>Ryan</p>
              <p>Programmer</p>
            </li>
            <li>
              <img src={img} alt="Tolunay"></img>
              <p>Tolunay</p>
              <p>Programmer</p>
            </li>
            <li>
              <img src={img} alt="Marko"></img>
              <p>Marko</p>
              <p>Coil Student</p>
            </li>
            <li>
              <img src={img} alt="Vyomika"></img>
              <p>Vyomika</p>
              <p>Coil Student</p>
            </li>
            <li>
              <img src={img} alt="Tim / Amy"></img>
              <p>Tim / Amy</p>
              <p>Teachers</p>
            </li>
          </ul>
        </div>
      </div>
      <MobileButtons />
    </BaseLayout>
  );
};

export default AboutPage;
