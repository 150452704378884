import { BaseLayout } from "../layout";


const AccountPage = () => {
    return (
        <BaseLayout>

            <h2>
                Profile
            </h2>
            <form>
                <label for="name">
                    Full name
                    <input type="text" name="name" required/>
                </label>

                <label for="number">
                    Phone number
                    <input type="text" name="number" required/>
                </label>

                <label for="mailaddress">
                    E-mailaddress
                    <input type="email" name="email" required/>
                </label>

                <label for="country">
                    Country
                    <input type="text" name="country" required/>
                </label>
            </form>

        </BaseLayout>
    );
};

export default AccountPage;