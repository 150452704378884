import styles from "./BaseLayout.module.scss";
import { Header, Sidebar } from "../components";

const BaseLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Sidebar />
      <main className={styles.main}>{children}</main>
    </>
  );
};

export default BaseLayout;
