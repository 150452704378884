import { RegisterLayout } from "../layout";

const RegisterPage = () => {

    return (
        <RegisterLayout>

        </RegisterLayout>
    );

};

export default RegisterPage;