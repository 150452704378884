import { BaseLayout } from "../layout";
import styles from "./HomePage.module.scss";
import img from "../assets/personal.png";
import MobileButtons from "../components/MobileButtons";

const HomePage = () => {
  return (
    <BaseLayout>
      <div className={styles.board}>
        <button>+ Add widget</button>

        <div className={styles.charts}>
          <img className={styles.graphic} src={img} alt="example img"/>
          <img className={styles.graphic} src={img} alt="example img" />
          <img className={styles.graphic} src={img} alt="example img" />
          <img className={styles.graphic} src={img} alt="example img" />
          <img className={styles.graphic} src={img} alt="example img" />
          <img className={styles.graphic} src={img} alt="example img" />
          <img className={styles.graphic} src={img} alt="example img" />
          <img className={styles.graphic} src={img} alt="example img" />
          <img className={styles.graphic} src={img} alt="example img" />
          <img className={styles.graphic} src={img} alt="example img" />
          <img className={styles.graphic} src={img} alt="example img" />
        </div>
      </div>
      <MobileButtons />
    </BaseLayout>
  );
};

export default HomePage;
