import { BaseLayout } from "../layout";
import styles from "./TimeLineViewPage.module.scss";
import hamburger from "../assets/hamburger.svg";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./TimeLineViewPage.module.scss";
import MobileButtons from "../components/MobileButtons";

const locales = {
    "nl-BE": require("date-fns/locale/nl-BE"),
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

const events = [
    {
        title: "Programming-5",
        allDay: true,
        start: new Date(2021, 6, 0),
        end: new Date(2021, 6, 0),
    },
    {
        title: "atwork-3",
        start: new Date(2021, 6, 7),
        end: new Date(2021, 6, 10),
    },
    {
        title: "CMS",
        start: new Date(2021, 6, 20),
        end: new Date(2021, 6, 23),
    },
];

function TimeLineViewPage() {
  const [newEvents, setNewEvent] = useState({ title: "", start: "", end: "" });
  const [allEvents, setAllEvents] = useState(events);

  function handleAddEvent() {
      setAllEvents([...allEvents, newEvents]);
  }

  return (
    <BaseLayout>
      <div className={styles.timeline}>
          <h1>Timeline</h1>
          {/* <h2>Calender</h2> */}
          <div className={styles.inputfields}>
              <input type="text" placeholder=" Add Task"  value={newEvents.title} onChange={(e) => setNewEvent({ ...newEvents, title: e.target.value })} />
              <DatePicker placeholderText=" Start Date" selected={newEvents.start} onChange={(start) => setNewEvent({ ...newEvents, start })} />
              <DatePicker placeholderText=" End Date" selected={newEvents.end} onChange={(end) => setNewEvent({ ...newEvents, end })} />
              <button onClick={handleAddEvent}>
                  Add Event
              </button>
          </div>
          <Calendar localizer={localizer} events={allEvents} startAccessor="start" endAccessor="end" style={{ height: 600, margin: "3.1rem" }} />
        </div>
        <MobileButtons />
      </BaseLayout>
  );
}

export default TimeLineViewPage;
