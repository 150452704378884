import { gql } from "@apollo/client";


/**
 * How to use:
 * const { loading, error, data } = useQuery(<<QUERY GOES HERE>>);
 */
export const GET_USERS = gql`
	query Users {
		users {
			id,
			first_name,
			last_name,
		}
	}
`;

/**
 * How to use:
 * const { loading, error, data } = useQuery(<<QUERY GOES HERE>>, {
 * 	variables: { input: <<ID GOES HERE>>}
 * });
 */
export const GET_ONE_USER = gql`
	query	getOneUser($input: Int!) {
		getUser(id: $input) {
			first_name,
			last_name
			credentialId
		}
	}
`

export const REMOVE_USER = gql`
	mutation removeUser($input: Int!) {
		removeUser(id: $input) {
		id,
		first_name
		}
	}
`;

export const UPDATE_USER = gql`
	mutation updateUser($id: Int!, $firstName: String!, $lastName: String!) {
		updateUser(updateUserInput: {
			id: $id,
			first_name: $firstName,
			last_name: $lastName,
		}) {
			id,
			first_name,
			last_name,
		}
	}
`;

export const CREATE_USER = gql`
	mutation createUser($firstName: String!, $lastName: String!, $pwd: String!, $email: String!) {
		createUser(createUserInput: {
				first_name: $firstName,
				last_name: $lastName},
			createCredentialInput: {
				pwd: $pwd,
				email: $email,
			}) {
			id,
			first_name,
			last_name,
			
		}
	}
`