import React, { useState } from "react";
import { BaseLayout } from "../layout";
import styles from "./PrivacyPage.module.scss";
import FAQ from "../components/FAQ";
import MobileButtons from "../components/MobileButtons";

const PrivacyPage = () => {
  const [faqs, setfaqs] = useState([
    {
      question: "How much does 2gether cost to use?",
      answer:
        "2gether is $30CAD per user, per month. Organizations are able to purchase the work management platform for students through the 2gether Business plan. Go to the Contact Us page and reach out with your pricing inquiry.",
      open: false,
    },
    {
      question: "How do I deactivate my account?",
      answer:
        "To deactivate your account, visit your profile and click Deactivate my account. You can reactivate your account at any time, and all your information will be saved.",
      open: false,
    },
    {
      question: "What happens if my team member leaves the platform?",
      answer:
        "If your team member deactivates their account, their tasks will still be visible to you. However, you will not be able to assign them new tasks or view their profile.",
      open: false,
    },
    {
      question: "Who can see my workspace and tasks?",
      answer:
        "Boards and tasks are categorized by teams. Only your team will be able to view the project details. ",
      open: false,
    },
    {
      question: "Is 2gether available in other languages?",
      answer:
        "2gether is available in English and Dutch currently. Future language support will be announced when they are ready.",
      open: false,
    },
    {
      question: "Does 2gether sell my information?",
      answer:
        "2gether does not sell information, all information used for the app and tasks are strictly confidential.",
      open: false,
    },
    {
      question: "Can you use 2gether in a web browser",
      answer:
        "Currently 2gether is not available for use in a web browser and must be downloaded.",
      open: false,
    },
    {
      question:
        "Does 2gether link with a cloud based file hosting service such as Dropbox?",
      answer:
        "2gether does not have compatibility with file hosting services at the moment.",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      })
    );
  };

  return (
    <BaseLayout>
      <div className={styles.privacy}>
        <h1>Privacy</h1>
        <div className={styles.text_privacy}>
          <p>
            2gether is committed to all collaborators’ privacy. All data and
            information provided to 2gether is carefully stored and encrypted
            for user’s data safety. 2gether is focused on giving users peace of
            mind when using confidential information for projects and tasks.
          </p>
          <p>
            <strong>Note:</strong> This section is only applicable to Canadian
            users.
          </p>
          <h4>Personal Data</h4>
          <p>
            Personal information such as Name, Date of Birth and Email are only
            collected for account registration purposes. Other collaborators
            will only see your name and email used to register your 2gether
            account.
          </p>
          <h4>Third-party Data Usage</h4>
          <p>
            2gether does not provide user data or information to third-parties
            without user consent nor for profit. 2gether also does not
            participate in ad analytics or user targeted marketing scoping with
            user data.
          </p>
          <h4>Legal Compliance, Law Enforcement and Emergency Data Access</h4>
          <p>
            2gether will release user data or project information for legal
            compliance with law enforcement or other applicable regulating
            bodies. 2gether will reserve the right to determine the necessary
            legal requirements needed for a release of user data such as warrant
            or legal request to access information.
          </p>
          <h4>E-mail or Phone Communication</h4>
          <p>
            2gether will not communicate news or updates to users’ Emails or
            phones without consent from users. 2gether will only communicate
            notifications or emergency security updates to users as required by
            law.
          </p>
        </div>

        <div>
          <h1>FAQ</h1>
          <div className={styles.faqs}>
            {faqs.map((faq, i) => (
              <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
            ))}
          </div>
        </div>
      </div>
      <MobileButtons />
    </BaseLayout>
  );
};

export default PrivacyPage;
