import React from "react";
import { Link } from "react-router-dom";
import * as Routes from "../routes";
import styles from "./MobileButtons.module.scss";
import mobhome from "../assets/home_mobile.svg";
import moblist from "../assets/list_mobile.svg";
import mobprof from "../assets/profile_mobile.svg";
import mobsett from "../assets/setting_mobile.svg";


const MobileButtons = () => {
  return (
    <>
      <div className={styles.mobile_buttons}>
        <ul>
          <li>
          <Link to={Routes.HOME}>
            <img className={styles.mob_but} src={mobhome} alt="logo" />
          </Link>
          </li>
          <li>
          <Link className={styles.infoSmall} to={Routes.LIST}>
            <img className={styles.mob_but} src={moblist} alt="logo" />
          </Link>
          </li>
          <li>
          <Link to={Routes.PROFILE}>
            <img className={styles.mob_but} src={mobprof} alt="logo" />
          </Link>
          </li>
          <li>
          <Link to={Routes.PRIVACY}>
            <img className={styles.mob_but} src={mobsett} alt="logo" />
          </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
export default MobileButtons;