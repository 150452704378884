import { BaseLayout } from "../layout";
import styles from "./CollabPage.module.scss";
import img from "../assets/profiel.svg";
import MobileButtons from "../components/MobileButtons";

const CollabPage = () => {
  return (
    <BaseLayout>
      <div className={styles.collab}>
        <div className={styles.team}>
          <h2>Team 1</h2>
          <span>Upcoming task</span>
        </div>

        <div className={styles.collab_in}>
          <img src={img} alt="profile" />
          <p>Jane Doe</p>
          <p>1 June 2021</p>
        </div>

        <div className={styles.collab_in}>
          <img src={img} alt="profile" />
          <p>Jane Doe</p>
          <p>1 June 2021</p>
        </div>

        <div className={styles.collab_in}>
          <img src={img} alt="profile" />
          <p>Jane Doe</p>
          <p>1 June 2021</p>
        </div>

        <div className={styles.collab_in}>
          <img src={img} alt="profile" />
          <p>Jane Doe</p>
          <p>1 June 2021</p>
        </div>

        <div>
          <button>+ Add member</button>
        </div>
        <div className={styles.team}>
          <h2>Team 2</h2>
          <span>Upcoming task</span>
        </div>

        <div className={styles.collab_in}>
          <img src={img} alt="profile"  />
          <p>Jane Doe</p>
          <p>1 June 2021</p>
        </div>

        <div className={styles.collab_in}>
          <img src={img} alt="profile"  />
          <p>Jane Doe</p>
          <p>1 June 2021</p>
        </div>

        <div className={styles.collab_in}>
          <img src={img} alt="profile"  />
          <p>Jane Doe</p>
          <p>1 June 2021</p>
        </div>

        <div className={styles.collab_in}>
          <img src={img} alt="profile"  />
          <p>Jane Doe</p>
          <p>1 June 2021</p>
        </div>
        
        <div>
          <button>+ Add member</button>
        </div>
      </div>
      <MobileButtons />
    </BaseLayout>
  );
};

export default CollabPage;
